<template>
  <div class="shadow-2xl rounded-3xl w-full mx-auto">
    <div class="intro-y box col-span-2">
      <div class="bg-white p-4 rounded shadow-sm">

        <!-- ADD Department Button -->
        <div class=" mb-4">
          <b-button variant="primary" @click="showAddDepartmentModal = true">
            ADD Department +
          </b-button>
        </div>

        <!-- Table to display departments -->
        <table class="table table-bordered table-hover">
          <thead class="bg-primary text-purple">
            <tr>
              <th class="text-center">SL</th>
              <th class="text-center">Department</th>
              <!-- <th class="text-center">Action</th>  -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(department, index) in departments" :key="index" class="bg-gray-100">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ department.name }}</td>
              <!-- <td class="text-center">
                <button 
                  class="btn btn-danger" 
                  @click="deleteDepartment(department.id)"
                >
                  Delete
                </button>
              </td> -->

            </tr>
          </tbody>
        </table>

        <!-- Add Department Modal -->
        <b-modal v-model="showAddDepartmentModal" title="Add New Department" hide-footer>
          <b-form @submit.prevent="submitDepartment">
            <b-form-group label="Department Name" label-for="name-input">
              <b-form-input id="name-input" v-model="newDepartment.name" required></b-form-input>
            </b-form-group>

            <b-form-group label="Description" label-for="description-input">
              <b-form-textarea id="description-input" v-model="newDepartment.description" required></b-form-textarea>
            </b-form-group>

            <b-form-group label="Email" label-for="email-input">
              <b-form-input id="email-input" type="email" v-model="newDepartment.email" required></b-form-input>
            </b-form-group>

            <b-form-group label="Order" label-for="order-input">
              <b-form-input id="order-input" type="number" v-model="newDepartment.order" required></b-form-input>
            </b-form-group>

            <div class="text-right">
              <b-button variant="secondary" @click="showAddDepartmentModal = false">Cancel</b-button>
              &nbsp;
              <b-button variant="primary" type="submit">Submit</b-button>
            </div>
          </b-form>
        </b-modal>

      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
  BModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
import Mixin from "@core/services/mixin";
import { GET_DEPARTMENTS, ADD_DEPARTMENT, DELETE_DEPARTMENT } from "@core/services/api";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  icon: 'EyeIcon',
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    BModal,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  data() {
    return {
      isp_id: JSON.parse(localStorage.getItem("userData")),
      departments: [],
      showAddDepartmentModal: false,
      department_id: '',
      newDepartment: {
        name: "",
        description: "",
        email: "",
        order: 1,
      },
    };
  },
  methods: {
    fetchDepartments() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_DEPARTMENTS, {
          params: {
            isp_id: this.isp_id.id,
          },
        })
        .then((res) => {
          this.departments = res.data.data.departments;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitDepartment() {
      this.$http
        .post(process.env.VUE_APP_BASEURL + ADD_DEPARTMENT, {
          isp_id: this.isp_id.id,
          name: this.newDepartment.name,
          description: this.newDepartment.description,
          email: this.newDepartment.email,
          order: this.newDepartment.order,
        })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Department added successfully!",
              variant: "success", // Sets the color to green for success
            },
          });
          this.resetDepartmentForm();
          this.fetchDepartments(); // Refresh the department list
          this.showAddDepartmentModal = false; // Close modal
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
            title: "Failed to add the department.",
            message: "Failed to add the department.",
            variant: "danger", // Sets the color to red for error
        },
          });
        });
    },
    resetDepartmentForm() {
      this.newDepartment = {
        name: "",
        description: "",
        email: "",
        order: 1,
      };
    },
    deleteDepartment(departmentId) {
      if (confirm("Are you sure you want to delete this department?")) {
        this.$http
        .post(process.env.VUE_APP_BASEURL + DELETE_DEPARTMENT, {
          department_id: departmentId,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Department deleted successfully!",
                variant: "success",
              },
            });
            // Refetch the departments list after deletion
            this.fetchDepartments();
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed to delete the department.",
                message: "Failed to delete the department.",
                variant: "danger",
              },
            });
          });
      }
    },
  },
  mounted() {
    this.fetchDepartments();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped>
.error-message {
  color: red;
}
</style>
